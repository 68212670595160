import { graphql, useStaticQuery } from "gatsby"

function usePlMenuIcons() {
  graphql`fragment menuImage on File {
    childImageSharp {
      gatsbyImageData(
        quality: 50,
        width: 110,
        placeholder: BLURRED,
        blurredOptions: {
          width: 50
        },
        layout: CONSTRAINED
      )
    }
  }`

  return useStaticQuery(graphql`
    query {
      indexOff: file(relativePath: { eq: "menu/pl/button_strona_główna_01.png" }) {
        ...menuImage
      }
      indexOn: file(relativePath: { eq: "menu/pl/button_strona_główna_02.png" }) {
        ...menuImage
      }
      eventsOff: file(relativePath: { eq: "menu/pl/button_koncerty_01.png" }) {
        ...menuImage
      }
      eventsOn: file(relativePath: { eq: "menu/pl/button_koncerty_02.png" }) {
        ...menuImage
      }
      mediaOff: file(relativePath: { eq: "menu/pl/button_media_01.png" }) {
        ...menuImage
      }
      mediaOn: file(relativePath: { eq: "menu/pl/button_media_02.png" }) {
        ...menuImage
      }
      shopOff: file(relativePath: { eq: "menu/pl/button_sklep_01.png" }) {
        ...menuImage
      }
      shopOn: file(relativePath: { eq: "menu/pl/button_sklep_02.png" }) {
        ...menuImage
      }
      aboutOff: file(relativePath: { eq: "menu/pl/button_o nas_01.png" }) {
        ...menuImage
      }
      aboutOn: file(relativePath: { eq: "menu/pl/button_o nas_02.png" }) {
        ...menuImage
      }
      contactOff: file(relativePath: { eq: "menu/pl/button_kontakt_01.png" }) {
        ...menuImage
      }
      contactOn: file(relativePath: { eq: "menu/pl/button_kontakt_02.png" }) {
        ...menuImage
      }
    }
  `);
}

export { usePlMenuIcons };
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby-plugin-intl"

import { highlightOff, highlightOn, menuItem, menuItemImg } from "./menu.module.css"

const MenuItem = ({ href, alt, iconOn, iconOff }) => {
  /*
   * We do not use `iconOn`, to reduce the number of bytes required for critical
   * components. Instead we use css filter on hover to simulate similar behavior.
   */

  return (
    <li className={menuItem}>
      <Link to={href}>
        <GatsbyImage
          image={iconOff.childImageSharp.gatsbyImageData}
          className={`${menuItemImg}`}
          alt={alt}
          loading="eager"
          fadeIn={false} />
      </Link>
    </li>
  );
}

export default MenuItem

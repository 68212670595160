import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";

import { highlightOff, highlightOn, socialItem, socialItemImg } from "./social.module.css"

const SocialItem = ({ href, alt, iconOn, iconOff }) => {
  return (
    <li>
      <a target="_blank" href={href} className={socialItem}>
        <GatsbyImage
          image={iconOff.childImageSharp.gatsbyImageData}
          className={`${highlightOff} ${socialItemImg}`}
          alt={alt}
          loading="eager"
          fadeIn={false} />
        <GatsbyImage
          image={iconOn.childImageSharp.gatsbyImageData}
          className={`${highlightOn} ${socialItemImg}`}
          alt={alt}
          loading="eager"
          fadeIn={false} />
      </a>
    </li>
  );
}

export default SocialItem

import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { BgImage } from 'gbimage-bridge';
import { useIntl } from "gatsby-plugin-intl"
import { getImage } from 'gatsby-plugin-image';

import {
  header,
  headerAbove,
  headerBelow,
  logoCenter,
  logoSide,
  menu, playerControls,
  playerCurrentName, playerIcon,
  playerIconCurrentSong, playerIconPlay, playerNextName,
  player as playerClass
} from "./header.module.css"
import MenuItem from "./menu-item"
import { useEnMenuIcons } from "./use-en-menu-icons"
import { usePlMenuIcons } from "./use-pl-menu-icons"
import Logo from "./logo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDoubleLeft, faAngleDoubleRight, faVolumeUp } from "@fortawesome/free-solid-svg-icons"
import { faPauseCircle, faPlayCircle } from "@fortawesome/free-regular-svg-icons"
import getSongs from "./get-songs"

const Header = () => {
  const intl = useIntl();

  const menuIcons = {
    'en': useEnMenuIcons(),
    'pl': usePlMenuIcons(),
  }[intl.locale]
  const data = useStaticQuery(graphql`{
    bg_top: file(relativePath: {eq: "bg_top.png"}) {
      childImageSharp {
        gatsbyImageData(
          quality: 80,
          placeholder: NONE,
          layout: FULL_WIDTH
        )
      }
    }
  }`);

  const bgImage = getImage(data.bg_top);

  return (
    <BgImage
      Tag="header"
      className={header}
      image={bgImage}
      style={{
        backgroundPosition: "center",
        backgroundRepeat: "repeat-x",
        backgroundSize: "auto 244px",
      }}
      critical={true}
      backgroundColor="rgba(62, 24, 24, 0.8)"
    >
      <div className={headerAbove}>
        <div>
          <Logo className={logoSide} />
        </div>
        <nav>
          <ul className={menu}>
            <MenuItem
              href="/"
              alt={intl.formatMessage({ id: "index_title" })}
              iconOn={menuIcons.indexOn}
              iconOff={menuIcons.indexOff}
            />
            <MenuItem
              href="/events"
              alt="Koncerty" // TODO(mlazowik): replace with translated strings
              iconOn={menuIcons.eventsOn}
              iconOff={menuIcons.eventsOff}
            />
            <MenuItem
              href="/media"
              alt="Media"
              iconOn={menuIcons.mediaOn}
              iconOff={menuIcons.mediaOff}
            />
            <li>
              <div>
                <Logo className={logoCenter} />
              </div>
            </li>
            <MenuItem
              href="/shop"
              alt="Sklep"
              iconOn={menuIcons.shopOn}
              iconOff={menuIcons.shopOff}
            />
            <MenuItem
              href="/about"
              alt="O nas"
              iconOn={menuIcons.aboutOn}
              iconOff={menuIcons.aboutOff}
            />
            <MenuItem
              href="/contact"
              alt="Kontakt"
              iconOn={menuIcons.contactOn}
              iconOff={menuIcons.contactOff}
            />
          </ul>
        </nav>
      </div>
      <div className={headerBelow}>
        <Player />
      </div>
    </BgImage>
  );
}

function Player() {
  const [songs, setSongs] = useState({});

  useEffect(() => {
    setSongs(
      shuffle(getSongs()).reduce((acc, song) => {
        const map = acc[0]
        const key = acc[1]
        map[key] = song;
        return [map, key + 1];
        }, [{}, 0])[0]
    )
  }, [])

  const player = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentId, setCurrentId] = useState(0);

  useEffect(() => {
    if (player.current === null) return;

    player.current.load();

    if (isPlaying) player.current.play();
  }, [currentId]);

  useEffect(() => {
    if (player.current === null) return;

    player.current.addEventListener("ended", next);

    return () => {
      player.current.removeEventListener("ended", next);
    }
  }, [player.current]);

  const count = Object.keys(songs).length;

  if (count === 0) {
    return <></>;
  }

  function getPreviousId(id) {
    return (id - 1 < 0) ? count - 1 : id - 1;
  }

  function getNextId(id) {
    return (id + 1) % count;
  }

  const prevId = getPreviousId(currentId);
  const nextId = getNextId(currentId);

  function togglePlay() {
    setIsPlaying((prevIsPlaying) => {
      // Cannot be done in useEffect, because Safari complains
      // https://webkit.org/blog/7734/auto-play-policy-changes-for-macos/
      if (player.current === null) return;

      player.current.load();

      if (!prevIsPlaying) player.current.play();
      if (prevIsPlaying) player.current.pause();

      return !prevIsPlaying;
    });
  }

  function prev() {
    setCurrentId(id => getPreviousId(id));
  }

  function next() {
    setCurrentId(id => getNextId(id));
  }

  return (
    <div className={playerClass}>
      <audio ref={player} style={{display: "none"}}>
        <source src={songs[currentId].url} type="audio/aac" />
      </audio>
      <div className={playerCurrentName}>
        <FontAwesomeIcon icon={faVolumeUp} className={playerIconCurrentSong} />
        <p>{songs[currentId].title}</p>
      </div>
      <div className={playerControls}>
        <a onClick={prev}>
          <FontAwesomeIcon icon={faAngleDoubleLeft} className={playerIcon} />
        </a>
        <a onClick={togglePlay} style={{
          display: isPlaying ? "none" : "initial"
        }}>
          <FontAwesomeIcon icon={faPlayCircle} className={playerIconPlay} />
        </a>
        <a onClick={togglePlay} style={{
          display: isPlaying ? "initial" : "none"
        }}>
          <FontAwesomeIcon icon={faPauseCircle} className={playerIconPlay} />
        </a>
        <a onClick={next}>
          <FontAwesomeIcon icon={faAngleDoubleRight} className={playerIcon} />
        </a>
      </div>
      <div className={playerNextName}>
        <p>{songs[nextId].title}</p>
      </div>
    </div>
  );
}

function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

export default Header

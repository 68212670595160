import candlekeep from "../music/Heroes Orchestra - Baldur's Candlekeep Tavern (from Baldur's Gate).m4a"
import bloodsail from "../music/Heroes Orchestra - Bloodsail Tavern (from World of Warcraft).m4a"
import grassland2 from "../music/Heroes Orchestra - Grassland from HoMM II.m4a"
import dirt3 from "../music/Heroes Orchestra - Dirt Theme from HoMM III.m4a"
import nature4 from "../music/Heroes Orchestra - Nature from HoMM IV.m4a"
import necropolis3 from "../music/Heroes Orchestra - Necropolis from HoMM III.m4a"
import stronghold3 from "../music/Heroes Orchestra - Stronghold from HoMM III.m4a"

function getSongs() {
  return [
    {
      title: "Baldur's Candlekeep Tavern (from Baldur's Gate)",
      url: candlekeep,
    },
    {
      title: "Bloodsail Tavern (from World of Warcraft)",
      url: bloodsail,
    },
    {
      title: "Grassland from HoMM II",
      url: grassland2,
    },
    {
      title: "Dirt Theme from HoMM III",
      url: dirt3,
    },
    {
      title: "Nature from HoMM IV",
      url: nature4,
    },
    {
      title: "Necropolis from HoMM III",
      url: necropolis3,
    },
    {
      title: "Stronghold from HoMM III",
      url: stronghold3,
    },
  ];
}

export default getSongs;

import { graphql, useStaticQuery } from "gatsby"

function useLangImages() {
  graphql`fragment langImage on File {
    childImageSharp {
      gatsbyImageData(
        width: 31,
        placeholder: NONE,
        layout: FIXED
      )
    }
  }`

  return useStaticQuery(graphql`
    query {
      enOn: file(relativePath: { eq: "lang/flag_uk_01.png" }) {
        ...langImage
      }
      enOff: file(relativePath: { eq: "lang/flag_uk_02.png" }) {
        ...langImage
      }
      plOn: file(relativePath: { eq: "lang/flag_pol_01.png" }) {
        ...langImage
      }
      plOff: file(relativePath: { eq: "lang/flag_pol_02.png" }) {
        ...langImage
      }
    }
  `)
}

export { useLangImages };
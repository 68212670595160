import { graphql, useStaticQuery } from "gatsby"

function useEnMenuIcons() {
  graphql`fragment menuImage on File {
    childImageSharp {
      gatsbyImageData(
        quality: 50,
        width: 110,
        placeholder: BLURRED,
        blurredOptions: {
          width: 50
        },
        layout: CONSTRAINED
      )
    }
  }`

  return useStaticQuery(graphql`
    query {
      indexOff: file(relativePath: { eq: "menu/en/ENG_button_home_01.png" }) {
        ...menuImage
      }
      indexOn: file(relativePath: { eq: "menu/en/ENG_button_home_02.png" }) {
        ...menuImage
      }
      eventsOff: file(relativePath: { eq: "menu/en/ENG_button_concerts_01.png" }) {
        ...menuImage
      }
      eventsOn: file(relativePath: { eq: "menu/en/ENG_button_concerts_02.png" }) {
        ...menuImage
      }
      mediaOff: file(relativePath: { eq: "menu/en/ENG_button_media_01.png" }) {
        ...menuImage
      }
      mediaOn: file(relativePath: { eq: "menu/en/ENG_button_media_02.png" }) {
        ...menuImage
      }
      shopOff: file(relativePath: { eq: "menu/en/ENG_button_store_01.png" }) {
        ...menuImage
      }
      shopOn: file(relativePath: { eq: "menu/en/ENG_button_store_02.png" }) {
        ...menuImage
      }
      aboutOff: file(relativePath: { eq: "menu/en/ENG_button_about us_01.png" }) {
        ...menuImage
      }
      aboutOn: file(relativePath: { eq: "menu/en/ENG_button_about us_02.png" }) {
        ...menuImage
      }
      contactOff: file(relativePath: { eq: "menu/en/ENG_button_contact_01.png" }) {
        ...menuImage
      }
      contactOn: file(relativePath: { eq: "menu/en/ENG_button_contact_02.png" }) {
        ...menuImage
      }
    }
  `);
}

export { useEnMenuIcons };
import React from "react"

import { social } from './social.module.css';
import SocialItem from "./social-item"
import { useSocialIcons } from "./use-social-icons"

const Social = () => {
  const socialIcons = useSocialIcons();

  return (
    <div className={social}>
      <ul>
        <SocialItem
          href="https://www.facebook.com/heroesorchestra/"
          alt="Facebook"
          iconOff={socialIcons.facebookOff}
          iconOn={socialIcons.facebookOn}
        />
        <SocialItem
          href="https://www.youtube.com/channel/UC_2fN8KSOJPRoE8PSN2ZtYA"
          alt="YouTube"
          iconOff={socialIcons.youtubeOff}
          iconOn={socialIcons.youtubeOn}
        />
        <SocialItem
          href="https://heroesorchestra.us10.list-manage.com/subscribe?u=69e6be4bd9335fd7345a899c5&id=b129019f20"
          alt="Newsletter"
          iconOff={socialIcons.mailOff}
          iconOn={socialIcons.mailOn}
        />
        <SocialItem
          href="https://www.instagram.com/heroesorchestra/"
          alt="Instagram"
          iconOff={socialIcons.instagramOff}
          iconOn={socialIcons.instagramOn}
        />
        <SocialItem
          href="https://soundcloud.com/heroesorchestra"
          alt="SoundCloud"
          iconOff={socialIcons.soundcloudOff}
          iconOn={socialIcons.soundcloudOn}
        />
      </ul>
    </div>
  )
}

export default Social

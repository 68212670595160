import React from "react"

import Header from "./header"
import Footer from "./footer"

import "./global.css"
import LangSelector from "./lang-selector"

const Layout = ({ children }) => {
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh"
    }}>
      <Header />
      <div style={{flex: "1"}}>
        {children}
      </div>
      <Footer />
      <LangSelector />
    </div>
  )
}

export default Layout

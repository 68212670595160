import { graphql, useStaticQuery } from "gatsby"
import Cookies from "js-cookie"
import React, { useEffect } from "react"
import { changeLocale as intlChangeLocale, useIntl } from "gatsby-plugin-intl"

import { flag, wrapper } from "./lang-selector.module.css"
import { useLangImages } from "./use-lang-images"
import { GatsbyImage } from "gatsby-plugin-image";

const LANGS = ["en", "pl"];
const LANG_COOKIE_NAME = "gatsby-intl-language";
const LANG_COOKIE_EXPIRY_DAYS = 365

const LangSelector = () => {
  const data = useStaticQuery(graphql`{
    bg_top: file(relativePath: {eq: "bg_bottom.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
  }`);

  const langImages = useLangImages();
  const intl = useIntl();

  const changeLocale = locale => {
    Cookies.set(LANG_COOKIE_NAME, locale,{
      expires: LANG_COOKIE_EXPIRY_DAYS
    });
    intlChangeLocale(locale);
  };

  /*
   * If the users switches locale manually, it gets saved in a cookie.
   * This allows us to restore the selection on revisit. This preference
   * has the highest priority, it should override browser preferred langs.
   *
   * To improve load performance we redirect users to browser-preferred
   * language using cloudflare's dynamic redirects. Those redirects can
   * access cookies, but only in paid plans. So, we switch to saved manually
   * selected language here.
   *
   * It should be ok perf-wise, as:
   * - small fraction of users will use the manual switching at all
   * - the main problem we are trying to avoid is black screen while js
   *   loads to redirect to language, but in this case a language is already
   *   selected, so the page starts loading, and only then it switches tha lang
   *   For example the background images are not modified at all when switching.
   */
  useEffect(() => {
    const locale = Cookies.get(LANG_COOKIE_NAME);

    if (locale != null && LANGS.includes(locale)) {
      changeLocale(locale);
    }
  }, [])

  if (intl.locale === "en") {
    return (
      <a
        onClick={() => changeLocale("pl")}
        className={wrapper}>
        <GatsbyImage
          image={langImages.plOn.childImageSharp.gatsbyImageData}
          className={flag}
          alt="Przełącz na język polski" />
      </a>
    );
  }

  return (
    <a
      onClick={() => changeLocale("en")}
      className={wrapper}>
      <GatsbyImage
        image={langImages.enOn.childImageSharp.gatsbyImageData}
        className={flag}
        alt="Switch to english" />
    </a>
  );
}

export default LangSelector;

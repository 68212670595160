exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../../../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-media-tsx": () => import("./../../../src/pages/media.tsx" /* webpackChunkName: "component---src-pages-media-tsx" */),
  "component---src-pages-shop-cds-tsx": () => import("./../../../src/pages/shop-cds.tsx" /* webpackChunkName: "component---src-pages-shop-cds-tsx" */),
  "component---src-pages-shop-confirmation-tsx": () => import("./../../../src/pages/shop-confirmation.tsx" /* webpackChunkName: "component---src-pages-shop-confirmation-tsx" */),
  "component---src-pages-shop-donate-confirmation-tsx": () => import("./../../../src/pages/shop-donate-confirmation.tsx" /* webpackChunkName: "component---src-pages-shop-donate-confirmation-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */)
}


import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import { BgImage } from 'gbimage-bridge';
import { useIntl } from "gatsby-plugin-intl"

import { footer } from './footer.module.css';
import Social from "./social"
import { Link } from "gatsby-plugin-intl"
import { donate, gold } from "../pages/about.module.css"
import { GatsbyImage } from "gatsby-plugin-image"

const Footer = () => {
  const data = useStaticQuery(graphql`{
  bg_top: file(relativePath: {eq: "bg_bottom.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
}`);

  const intl = useIntl();

  return (
    <BgImage
      Tag="footer"
      className={footer}
      image={data.bg_top.childImageSharp.gatsbyImageData}
      style={{
        backgroundPosition: "center",
        backgroundRepeat: "repeat-x",
        backgroundSize: "auto 232px",
      }}
    >
      <Social />
      <p>
        <a href="https://heroesorchestra.us10.list-manage.com/subscribe?u=69e6be4bd9335fd7345a899c5&id=b129019f20" target="_blank">
          {intl.formatMessage({ id: "subscribe" })}
        </a>
      </p>
      <p>
        <Link to="/donate">
          {intl.formatMessage({ id: "donate-cta" })}
        </Link>
      </p>
      <p><small>Website by <a href="https://twitter.com/mlazowik" target="_blank">@mlazowik</a></small></p>
    </BgImage>
  );
}

export default Footer

// extracted by mini-css-extract-plugin
export var header = "header-module--header--666b3";
export var headerAbove = "header-module--header-above--26802";
export var headerBelow = "header-module--header-below--17f83";
export var headerBg = "header-module--header-bg--461cd";
export var logoCenter = "header-module--logo-center--afb42";
export var logoSide = "header-module--logo-side--26a8c";
export var menu = "header-module--menu--4f3a7";
export var player = "header-module--player--2a073";
export var playerControls = "header-module--player-controls--bf395";
export var playerCurrentName = "header-module--player-current-name--8ae5d";
export var playerIcon = "header-module--player-icon--28412";
export var playerIconCurrentSong = "header-module--player-icon-current-song--8d903 header-module--player-icon--28412";
export var playerIconPlay = "header-module--player-icon-play--bd12c";
export var playerNextName = "header-module--player-next-name--7d347";
import { graphql, useStaticQuery } from "gatsby"

function useSocialIcons() {
  graphql`fragment socialImage on File {
  childImageSharp {
    gatsbyImageData(quality: 20, layout: FULL_WIDTH)
  }
}`

  return useStaticQuery(graphql`
    query {
      facebookOff: file(relativePath: { eq: "social/facebook_icon_01.png" }) {
        ...socialImage
      }
      facebookOn: file(relativePath: { eq: "social/facebook_icon_02.png" }) {
        ...socialImage
      }
      youtubeOff: file(relativePath: { eq: "social/youtube_icon_01.png" }) {
        ...socialImage
      }
      youtubeOn: file(relativePath: { eq: "social/youtube_icon_02.png" }) {
        ...socialImage
      }
      mailOff: file(relativePath: { eq: "social/Gmail_icon_01.png" }) {
        ...socialImage
      }
      mailOn: file(relativePath: { eq: "social/Gmail_icon_02.png" }) {
        ...socialImage
      }
      instagramOff: file(relativePath: { eq: "social/Instagram_icon_01.png" }) {
        ...socialImage
      }
      instagramOn: file(relativePath: { eq: "social/Instagram_icon_02.png" }) {
        ...socialImage
      }
      soundcloudOff: file(relativePath: { eq: "social/Soundcloud_icon_01.png" }) {
        ...socialImage
      }
      soundcloudOn: file(relativePath: { eq: "social/Soundcloud_icon_02.png" }) {
        ...socialImage
      }
    }
  `)
}

export { useSocialIcons };